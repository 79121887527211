import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LogTransaction = () => {
  const [LogTransactionID, setRefundTransactionID] = useState('');
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false); // Добавленное состояние

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.rts-info.com/control/get-log-transaction?transactionID=${LogTransactionID}`,
          {
            headers: {
              'accept': 'application/json',
            },
          }
        );
        // console.log(response.data);
        setTransactionData(response.data.map(JSON.parse));
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setTransactionData(null);
        setError('Error fetching transaction data. Please check the TransactionID.');
      }
    };

    if (submitted && LogTransactionID) { // Выполнять запрос только при submitted и RefundTransactionID
      fetchData();
      setSubmitted(false); // Сбросить submitted после выполнения запроса
    }
}, [submitted, LogTransactionID]);

const handleInputChange = (event) => {
    setRefundTransactionID(event.target.value.trim());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (!uuidRegex.test(LogTransactionID)) {
      setError('Invalid UUID format. Please enter a valid LogTransactionID.');
      return;
    }
    // Очищаем ошибку перед отправкой запроса
    setError(null);
    setSubmitted(true);
  };

  const handleReset = () => {
    setRefundTransactionID('');
    setTransactionData(null);
    setError(null);
    setSubmitted(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div className="row g-3 align-items-center">
        <div className="col-auto">
          <label htmlFor="inputLogTransactionID" className="col-form-label">Transaction ID:</label>
        </div>
        <div className="col-auto">
          <input
            type="text"
            value={LogTransactionID}
            onChange={handleInputChange}
            placeholder="Enter TransactionID"
            className="form-control"
            id="inputLogTransactionID"
            size={40}
          />
        </div>
        <div className="col-auto">
        <button className="btn btn-primary" type="submit">Submit</button>
        </div>
        <div className="col-auto">
        <button className="btn btn-secondary" type="button" onClick={handleReset}>Reset</button> {/* Добавленная кнопка сброса */}
        </div>
      </div>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {transactionData && (
        <div>
          {transactionData.map((data, index) => (
            <div key={index}>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </div>
          ))}
        </div>
        )
      }
    </div>
  );
};

export default LogTransaction;
