import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RefundTransaction = () => {
  const [refundTransactionID, setRefundTransactionID] = useState('');
  const [transactionData, setTransactionData] = useState(null);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false); // Добавленное состояние

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://backend.rts-info.com/control/get-refund-transaction?refundTransactionID=${refundTransactionID}`,
          {
            headers: {
              'accept': 'application/json',
            },
          }
        );
        setTransactionData(response.data.Transaction);
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setTransactionData(null);
        setError('Error fetching transaction data. Please check the RefundTransactionID.');
      }
    };

    if (submitted && refundTransactionID) { // Выполнять запрос только при submitted и RefundTransactionID
      fetchData();
      setSubmitted(false); // Сбросить submitted после выполнения запроса
    }
}, [submitted, refundTransactionID]);

const handleInputChange = (event) => {
    setRefundTransactionID(event.target.value.trim());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (!uuidRegex.test(refundTransactionID)) {
      setError('Invalid UUID format. Please enter a valid refundTransactionID.');
      return;
    }
    // Очищаем ошибку перед отправкой запроса
    setError(null);
    setSubmitted(true);
  };

  const handleReset = () => {
    setRefundTransactionID('');
    setTransactionData(null);
    setError(null);
    setSubmitted(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div className="row g-3 align-items-center">
        <div className="col-auto">
          <label htmlFor="inputRefundTransactionID" className="col-form-label">Refund Transaction ID:</label>
        </div>
        <div className="col-auto">
          <input
            type="text"
            value={refundTransactionID}
            onChange={handleInputChange}
            placeholder="Enter RefundTransactionID"
            className="form-control"
            id="inputRefundTransactionID"
            size={40}
          />
        </div>
        <div className="col-auto">
        <button className="btn btn-primary" type="submit">Submit</button>
        </div>
        <div className="col-auto">
        <button className="btn btn-secondary" type="button" onClick={handleReset}>Reset</button> {/* Добавленная кнопка сброса */}
        </div>
      </div>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {transactionData ? (
        <div>
          <h2>Transaction Details</h2>
          <table className="table table-striped">
            <tbody>
            <tr><td>Sale Transaction ID:</td><td> {transactionData.saleTransactionID}</td></tr>
            <tr><td>Sale Amount:</td><td> {transactionData.saleAmount}</td></tr>
            <tr><td>Sale Date:</td><td> {transactionData.saleDate}</td></tr>
            <tr><td>transactionTZ:</td><td> {transactionData.transactionTZ}</td></tr>
            <tr><td>dateServer:</td><td> {transactionData.dateServer}</td></tr>
            <tr><td>tzServer:</td><td> {transactionData.tzServer}</td></tr>
            <tr><td>discountAmount:</td><td> {transactionData.discountAmount}</td></tr>
            <tr><td>transactionStatus:</td><td> {transactionData.transactionStatus}</td></tr>
            <tr><td>merchantName:</td><td> {transactionData.merchantName}</td></tr>
            <tr><td>merchantTIN:</td><td> {transactionData.merchantTIN}</td></tr>
            <tr><td>merchantID:</td><td> {transactionData.merchantID}</td></tr>
            <tr><td>storeID:</td><td> {transactionData.storeID}</td></tr>
            <tr><td>storeAddress:</td><td> {transactionData.storeAddress}</td></tr>
            <tr><td>storeName:</td><td> {transactionData.storeName}</td></tr>
            <tr><td>storeIndustryID:</td><td> {transactionData.storeIndustryID}</td></tr>
            <tr><td>posID:</td><td> {transactionData.posID}</td></tr>
            <tr><td>posSerialNumber:</td><td> {transactionData.posSerialNumber}</td></tr>
            <tr><td>posAppVersion:</td><td> {transactionData.posAppVersion}</td></tr>
            <tr><td>posLatitude:</td><td> {transactionData.posLatitude}</td></tr>
            <tr><td>posLongitude:</td><td> {transactionData.posLongitude}</td></tr>
            <tr><td>address_by_coord:</td><td> {transactionData.address_by_coord}</td></tr>
            <tr><td>userID:</td><td> {transactionData.userID}</td></tr>
            <tr><td>userName:</td><td> {transactionData.userName}</td></tr>
            <tr><td>payments.paymentDate:</td><td> {transactionData.payments_paymentDate}</td></tr>
            <tr><td>payments.paymentID:</td><td> {transactionData.payments_paymentID}</td></tr>
            <tr><td>payments.paymentAmount:</td><td> {transactionData.payments_paymentAmount}</td></tr>
            <tr><td>payments.paymentType:</td><td> {transactionData.payments_paymentType}</td></tr>
            <tr><td>payments.paymentSystemName:</td><td> {transactionData.payments_paymentSystemName}</td></tr>
            <tr><td>productList.quantity:</td><td> {transactionData.productList_quantity}</td></tr>
            <tr><td>productList.discount:</td><td> {transactionData.productList_discount}</td></tr>
            
            <tr><td>productList.productPrice:</td><td> {Array.isArray(transactionData.productList_productPrice) ? transactionData.productList_productPrice.join(', ') : transactionData.productList_productPrice}</td></tr>
            <tr><td>productList.productID:</td><td> {Array.isArray(transactionData.productList_productID) ? transactionData.productList_productID.join(', ') : transactionData.productList_productID}</td></tr>
            <tr><td>productList.integerUnit:</td><td> {Array.isArray(transactionData.productList_integerUnit) ? transactionData.productList_integerUnit.join(', ') : transactionData.productList_integerUnit}</td></tr>
            <tr><td>productList.productName:</td><td> {Array.isArray(transactionData.productList_productName) ? transactionData.productList_productName.join(', ')  : transactionData.productList_productName}</td></tr>
            <tr><td>productList.psid:</td><td> {Array.isArray(transactionData.productList_psid) ? transactionData.productList_psid.join(', ')  : transactionData.productList_psid}</td></tr>
            <tr><td>productList.unitCode:</td><td> {Array.isArray(transactionData.productList_unitCode) ? transactionData.productList_unitCode.join(', ')  : transactionData.productList_unitCode}</td></tr>
            <tr><td>productList.units:</td><td> {Array.isArray(transactionData.productList_units) ? transactionData.productList_units.join(', ')  : transactionData.productList_units}</td></tr>
            <tr><td>productList.marked:</td><td> {Array.isArray(transactionData.productList_marked) ? transactionData.productList_marked.join(', ')  : transactionData.productList_marked}</td></tr>
            <tr><td>productList.vat:</td><td> {Array.isArray(transactionData.productList_vat) ? transactionData.productList_vat.join(', ')  : transactionData.productList_vat}</td></tr>

            <tr><td>refundAmount:</td><td> {transactionData.refundAmount}</td></tr>
            <tr><td>refundDate:</td><td> {transactionData.refundDate}</td></tr>
            <tr><td>refundTransactionID:</td><td> {transactionData.refundTransactionID}</td></tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <h2>No data</h2>
        </div>
      )}
    </div>
  );
};

export default RefundTransaction;
