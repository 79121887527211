// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: left;
}

.active {
  color: lightyellow;
  text-decoration: none;
}

.activeLink {
  color: lightyellow;
  text-decoration: none;
}

.courses {
  max-width: 90%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 40px;
}

.course {
  background-color: rgb(100, 82, 0);
  width: 250px;
  border: 1px lightsalmon solid;
  border-radius: 5px;
  margin: 10px;
  padding: 15px;
  padding-bottom: 40px;
}

h1 {
  font-size: 90px;
  margin-top: 20px;
}
a {
  color: lightblue;
  font-size: 25px;
}

nav {
  /* background-color: rgb(103, 64, 102); */
  padding: 5px;
}

nav a {
  margin: 15px;
}

button {
  padding: 10px;
  font-size: 30px;
  border-radius: 5px;
  border: 0;
  margin: 20px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;EACd,aAAa;AACf;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,yCAAyC;EACzC,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".App {\n  text-align: left;\n}\n\n.active {\n  color: lightyellow;\n  text-decoration: none;\n}\n\n.activeLink {\n  color: lightyellow;\n  text-decoration: none;\n}\n\n.courses {\n  max-width: 90%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  justify-content: space-between;\n  margin: 0 auto;\n  padding: 40px;\n}\n\n.course {\n  background-color: rgb(100, 82, 0);\n  width: 250px;\n  border: 1px lightsalmon solid;\n  border-radius: 5px;\n  margin: 10px;\n  padding: 15px;\n  padding-bottom: 40px;\n}\n\nh1 {\n  font-size: 90px;\n  margin-top: 20px;\n}\na {\n  color: lightblue;\n  font-size: 25px;\n}\n\nnav {\n  /* background-color: rgb(103, 64, 102); */\n  padding: 5px;\n}\n\nnav a {\n  margin: 15px;\n}\n\nbutton {\n  padding: 10px;\n  font-size: 30px;\n  border-radius: 5px;\n  border: 0;\n  margin: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
