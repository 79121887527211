import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <h1>404</h1>
      <h2>Not Found</h2>
      <Link to=".." relative="path">
        Back
      </Link>
    </>
  )
}

export default NotFound
