import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TransactionDetails from './TransactionDetails';
import './TransactionList.css';

const API_BASE_URL = "https://backend.rts-info.com"
// const API_BASE_URL = "http://192.168.14.63:8888"

const TransactionList = () => {
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // состояние для отслеживания загрузки

    useEffect(() => {
        fetchTransactions();
    }, []);

    const fetchTransactions = async () => {
        setIsLoading(true); // Включаем индикатор загрузки
        try {
            const response = await axios.get(`${API_BASE_URL}/top10/transactions/top10`);
            setTransactions(response.data);
        } catch (error) {
            console.error("Error fetching transactions", error);
        } finally {
            setIsLoading(false); // Отключаем индикатор загрузки после завершения запроса
        }
    };

    const viewTransaction = (saleTransactionID) => {
        setSelectedTransaction(saleTransactionID);
    };

    const downloadTransaction = async (saleTransactionID) => {
        try {
            const response = await axios.post(`${API_BASE_URL}/top10/transactions/save/${saleTransactionID}`, {}, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${saleTransactionID}.json`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error downloading transaction", error);
        }
    };

    // const deleteTransaction = async (saleTransactionID) => {
    //     try {
    //         await axios.delete(`${API_BASE_URL}/top10/transactions/delete/${saleTransactionID}`);
    //         fetchTransactions(); // обновить список после удаления
    //     } catch (error) {
    //         console.error("Error deleting transaction", error);
    //     }
    // };

    const deleteTransaction = async (saleTransactionID) => {
        const confirmation = window.confirm(`Are you sure you want to delete transaction ${saleTransactionID}? This action cannot be undone.`);
        
        if (confirmation) {
            try {
                await axios.delete(`${API_BASE_URL}/top10/transactions/delete/${saleTransactionID}`);
                fetchTransactions(); // обновить список после удаления
            } catch (error) {
                console.error("Error deleting transaction", error);
            }
        } else {
            console.log("Transaction deletion cancelled");
        }
    };

    return (
        <div>
            <h2>Top 10 Transactions</h2>
            {isLoading ? (
                // Выводим сообщение "Loading" или изменяем курсор на часы
                <div className="loading">
                    Loading...
                </div>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Amount</th>
                            <th>Sale Date</th>
                            <th>Merchant</th>
                            <th>Store</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions.map((transaction) => (
                            <tr key={transaction.saleTransactionID}>
                                <td>{transaction.saleTransactionID}</td>
                                <td>{transaction.saleAmount?.toLocaleString('us-US', { style: 'currency', currency: 'UZS' })}</td>
                                <td>{transaction.saleDate}</td>
                                <td>{transaction.merchantName}</td>
                                <td>{transaction.storeName}</td>
                                <td>{transaction.transactionStatus}</td>
                                <td>
                                    <button className="btn btn-success" onClick={() => viewTransaction(transaction.saleTransactionID)}>View</button>&nbsp;
                                    <button className="btn btn-warning" onClick={() => downloadTransaction(transaction.saleTransactionID)}>Download</button>&nbsp;
                                    <button className="btn btn-danger" onClick={() => deleteTransaction(transaction.saleTransactionID)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {selectedTransaction && <TransactionDetails saleTransactionID={selectedTransaction} onClose={() => setSelectedTransaction(null)} />}
        </div>
    );
};

export default TransactionList;
