import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

const API_BASE_URL = "https://backend.rts-info.com"
// const API_BASE_URL = "http://192.168.14.63:8888"

const TransactionDetails = ({ saleTransactionID, onClose }) => {
    const [transaction, setTransaction] = useState(null);

    const fetchTransactionDetails = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/top10/transactions/sale/${saleTransactionID}`);
            setTransaction(response.data);
        } catch (error) {
            console.error("Error fetching transaction details", error);
        }
    }, [saleTransactionID]); 

    useEffect(() => {
        fetchTransactionDetails();
    }, [fetchTransactionDetails]);

    // useEffect(() => {
    //     console.log(transaction);
    //   }, [transaction]);
      
    return (
        <Modal isOpen={!!saleTransactionID} onRequestClose={onClose}>
            {transaction ? (
                <div>
                    <h2>Transaction Details</h2>
                    <table className="table table-striped">
                        <tbody>
                            <tr><td>Sale Transaction ID:</td><td> {transaction?.saleTransactionID}</td></tr>
                            <tr><td>Sale Amount:</td><td> {transaction?.saleAmount?.toLocaleString('us-US', { style: 'currency', currency: 'UZS' }) || 'N/A'}</td></tr>
                            <tr><td>Sale Date:</td><td> {transaction?.saleDate}</td></tr>
                            <tr><td>transactionTZ:</td><td> {transaction?.transactionTZ}</td></tr>
                            <tr><td>dateServer:</td><td> {transaction?.dateServer}</td></tr>
                            <tr><td>tzServer:</td><td> {transaction?.tzServer}</td></tr>
                            <tr><td>discountAmount:</td><td> {transaction?.discountAmount}</td></tr>
                            <tr><td>transactionStatus:</td><td> {transaction?.transactionStatus}</td></tr>
                            <tr><td>merchantName:</td><td> {transaction?.merchantName}</td></tr>
                            <tr><td>merchantTIN:</td><td> {transaction?.merchantTIN}</td></tr>
                            <tr><td>merchantID:</td><td> {transaction?.merchantID}</td></tr>
                            <tr><td>storeID:</td><td> {transaction?.storeID}</td></tr>
                            <tr><td>storeAddress:</td><td> {transaction?.storeAddress}</td></tr>
                            <tr><td>storeName:</td><td> {transaction?.storeName}</td></tr>
                            <tr><td>storeIndustryID:</td><td> {transaction?.storeIndustryID}</td></tr>
                            <tr><td>posID:</td><td> {transaction?.posID}</td></tr>
                            <tr><td>posSerialNumber:</td><td> {transaction?.posSerialNumber}</td></tr>
                            <tr><td>posAppVersion:</td><td> {transaction?.posAppVersion}</td></tr>
                            <tr><td>posLatitude:</td><td> {transaction?.posLatitude}</td></tr>
                            <tr><td>posLongitude:</td><td> {transaction?.posLongitude}</td></tr>
                            <tr><td>address_by_coord:</td><td> {transaction?.address_by_coord}</td></tr>
                            <tr><td>userID:</td><td> {transaction?.userID}</td></tr>
                            <tr><td>userName:</td><td> {transaction?.userName}</td></tr>

                            <tr><td>Payments Dates:</td><td>{transaction?.payments?.map(p => p.paymentDate).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Payments IDs:</td><td>{transaction?.payments?.map(p => p.paymentID).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Payments Amounts:</td><td>{transaction?.payments?.map(p => p.paymentAmount?.toLocaleString('us-US', { style: 'currency', currency: 'UZS' })).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Payments Types:</td><td>{transaction?.payments?.map(p => p.paymentType).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Payments System Names:</td><td>{transaction?.payments?.map(p => p.paymentSystemName || 'N/A').join(', ')}</td></tr>

                            <tr><td>Product Quantities:</td><td>{transaction?.productList?.map(p => p.quantity).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Product Discounts:</td><td>{transaction?.productList?.map(p => p.discount).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Product Prices:</td><td>{transaction?.productList?.map(p => p.productPrice?.toLocaleString('us-US', { style: 'currency', currency: 'UZS' })).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Product IDs:</td><td>{transaction?.productList?.map(p => p.productID).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Product Names:</td><td>{transaction?.productList?.map(p => p.productName).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Product Units:</td><td>{transaction?.productList?.map(p => p.units).join(', ') || 'N/A'}</td></tr>
                            <tr><td>Product VAT:</td><td>{transaction?.productList?.map(p => p.vat).join(', ') || 'N/A'}</td></tr>

                            <tr><td>Refund Amount:</td><td> {transaction?.refundAmount || 'N/A'}</td></tr>
                            <tr><td>Refund Date:</td><td> {transaction?.refundDate || 'N/A'}</td></tr>
                            <tr><td>Refund Transaction ID:</td><td> {transaction?.refundTransactionID || 'N/A'}</td></tr>
                        </tbody>
                    </table>

                    <button className="btn btn-primary w-100" onClick={onClose}>Close</button>
                </div>
            ) : (
                <p>Loading transaction details...</p>
            )}
        </Modal>
    );
};

export default TransactionDetails;
