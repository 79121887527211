import { NavLink } from 'react-router-dom'

const Menu = () => {
  return (
    <nav>
      <NavLink
        // className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
        style={({ isActive }) =>
          isActive ? { color: 'lightgrey', textDecoration: 'none' } : {}
        }
        to="."
        end
      >
        Home
      </NavLink>
      <NavLink 
        style={({ isActive }) =>
          isActive ? { color: 'lightgrey', textDecoration: 'none' } : {}
        }
        to="sale"
        end
      >
        Sale
      </NavLink>
      <NavLink 
        style={({ isActive }) =>
          isActive ? { color: 'lightgrey', textDecoration: 'none' } : {}
        }
        to="refund"
        end
      >
        Refund
      </NavLink>
      <NavLink 
        style={({ isActive }) =>
          isActive ? { color: 'lightgrey', textDecoration: 'none' } : {}
        }
        to="log-details"
        end
      >
        Log Search
      </NavLink>
      <NavLink 
        style={({ isActive }) =>
          isActive ? { color: 'lightgrey', textDecoration: 'none' } : {}
        }
        to="search-product"
        end
      >
        Search PSID
      </NavLink>
      <NavLink 
        style={({ isActive }) =>
          isActive ? { color: 'lightgrey', textDecoration: 'none' } : {}
        }
        to="top10"
        end
      >
        TOP 10
      </NavLink>
    </nav>
    // <nav>
    //   <NavLink to="/">Home</NavLink>
    //   <NavLink to="/about">About</NavLink>
    //   <NavLink to="/contacts">Contacts</NavLink>
    // </nav>
  )
}

export default Menu
