// src/App.js
import React from 'react';
import TransactionList from './TransactionList';
// import './App.css';

function AppTr() {
    return (
        <div className="App">
            <TransactionList />
        </div>
    );
}

export default AppTr;
