import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css'

import MainLayout from './layouts/MainLayout'
import Home from './components/Home'
import NotFound from './components/NotFound'
import RefundTransaction from './components/RefundTransaction';
import SaleTransaction from './components/SaleTransaction';
import LogTransaction from './components/LogTransaction';
import SearchProduct from './components/SearchPSID';
import AppTr from './components/top10/AppTr';


function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <h1>Контроль транзакций</h1>
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="sale" element={<SaleTransaction />} />
            <Route path="refund" element={<RefundTransaction />} />
            <Route path="log-details" element={<LogTransaction />} />
            <Route path="search-product" element={<SearchProduct />} />
            <Route path="top10" element={<AppTr />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;