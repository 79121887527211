// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/TransactionList.css */
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px 12px;
    border: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
}

button {
    margin-right: 8px;
    padding: 6px 12px;
    cursor: pointer;
}

.loading {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
}

/* Изменение курсора на часы во время загрузки */
.loading, .loading * {
    cursor: wait; /* Устанавливаем курсор на часы */
}
`, "",{"version":3,"sources":["webpack://./src/components/top10/TransactionList.css"],"names":[],"mappings":"AAAA,uCAAuC;AACvC;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;AACjB;;AAEA,gDAAgD;AAChD;IACI,YAAY,EAAE,iCAAiC;AACnD","sourcesContent":["/* src/components/TransactionList.css */\ntable {\n    width: 100%;\n    border-collapse: collapse;\n}\n\nth, td {\n    padding: 8px 12px;\n    border: 1px solid #ddd;\n}\n\nth {\n    background-color: #f4f4f4;\n}\n\nbutton {\n    margin-right: 8px;\n    padding: 6px 12px;\n    cursor: pointer;\n}\n\n.loading {\n    font-size: 20px;\n    font-weight: bold;\n    text-align: center;\n    padding: 20px;\n}\n\n/* Изменение курсора на часы во время загрузки */\n.loading, .loading * {\n    cursor: wait; /* Устанавливаем курсор на часы */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
