import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SaleTransaction = () => {
  const [productId, setProductId] = useState('');
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false); // Добавленное состояние

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://psid.rts-info.com/product/${productId}`,
          {
            headers: {
              'accept': 'application/json',
            },
          }
        );
        setProductData(response.data);
        // console.log(response.data)
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setProductData(null);
        setError('Error fetching data. Please check the productId.');
      }
    };

    if (submitted && productId) { // Выполнять запрос только при submitted и productId
      fetchData();
      setSubmitted(false); // Сбросить submitted после выполнения запроса
    }
}, [submitted, productId]);

const handleInputChange = (event) => {
    setProductId(event.target.value.trim());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

//    const psidRegex = /^[0-9].$/;
//    if (!psidRegex.test(productId)) {
//      setError('Invalid PSID format. Please enter a valid productId.');
//      return;
//    }
    // Очищаем ошибку перед отправкой запроса
    setError(null);
    setSubmitted(true);
  };

  const handleReset = () => {
    setProductId('');
    setProductData(null);
    setError(null);
    setSubmitted(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div className="row g-3 align-items-center">
        <div className="col-auto">
          <label htmlFor="inputproductId" className="col-form-label">Product ID:</label>
        </div>
        <div className="col-auto">
          <input
            type="text"
            value={productId}
            onChange={handleInputChange}
            placeholder="Enter productId"
            className="form-control"
            id="inputproductId"
            size={40}
          />
        </div>
        <div className="col-auto">
        <button className="btn btn-primary" type="submit">Submit</button>
        </div>
        <div className="col-auto">
        <button className="btn btn-secondary" type="button" onClick={handleReset}>Reset</button> {/* Добавленная кнопка сброса */}
        </div>
      </div>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {productData ? (
          <div>
          <h2>Product Details</h2>
            <table className="table table-striped">
              <tbody>
              <tr><td>Product ID:</td><td> {productData.PSID}</td></tr>
              <tr><td>Product Name:</td><td> {productData.Name}</td></tr>
              </tbody>
              </table>
          </div> 
          ) : ( 
            <div>
              <h2>No data</h2>
            </div>
          )
        }
    </div>
  );
};

export default SaleTransaction;
